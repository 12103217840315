import { attachEvent, debounce, toggleSearchClass } from '../../../../js/default';
import { searchApiCall } from '../../../../js/api';

const searchInput = document.querySelector('.header-search__input input');

if (searchInput) {
	const headerSearch = document.querySelector('.header-search');
	const searchButton = document.querySelector('.site-header__search-btn');

	const resetSearch = () => {
		headerSearch.classList.remove('show-reset-btn');
		headerSearch.querySelector('.mod-input').classList.remove('is-focused');
		headerSearch.querySelector('.header-search__submit')?.classList.remove('search-active');
		searchInput.value = '';
	};

	searchInput.addEventListener('input', () => {
		headerSearch.classList.toggle('show-reset-btn', searchInput.value.trim() !== '');
	});

	attachEvent('.header-search__reset', 'click', () => {
		resetSearch();
	});

	attachEvent('.site-header__search-btn', 'click', (e) => {
		const isOpen = document.body.classList.contains('is-open-search');
		toggleSearchClass(!isOpen, searchInput);
		e.preventDefault();
	});

	// Close search on outside click
	document.addEventListener('click', (evt) => {
		let targetElement = evt.target; // clicked element

		do {
			if (targetElement === headerSearch || targetElement === searchButton) {
				return;
			}
			targetElement = targetElement.parentNode;
		} while (targetElement);

		const isOpen = document.body.classList.contains('is-open-search');

		if (isOpen) {
			toggleSearchClass(false, searchInput);
			resetSearch();
		}
	});

	searchInput.addEventListener(
		'keyup',
		debounce(async (ev) => {
			const searchResults = document.querySelector('.header-search__result');
			let response = {};
			if (searchResults && ev.target.value !== '') {
				response = await searchApiCall(ev.target.value);
				searchResults.outerHTML = response.html;
			}
			headerSearch.querySelector('.header-search__submit')?.classList.toggle('search-active', !!response.product_count);
		}, 300),
	);
}
